import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w09KG09Origami = () => (
  <Layout>
    <SEO title="w09KG09Origami" />

    <h1 id="ueberschrift">Kreatives Gestalten</h1>
    <h2>Origami für Groß und Klein &emsp;  ©&nbsp;1995</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w09KG09Origami&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Figuren, Reliefs, Schachteln, etc.;<br />
        aus einem Bogen Papier (maximal zwei);<br />
        durch falten, kleben und schneiden.
      </p>
      <p>
        Gemeinsam mit anderen können Sie Ihr kreatives Talent suchen und entdecken, indem Sie 
        unter Anleitung verschiedene Dinge aus Papier falten.
        Genießen Sie die Zeit beim geselligen Experimentieren, Fluchen, Beneiden und Freuen.
      </p>

      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Mitmachkurs
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Tisch pro Teilnehmer<br/>
        Materialbedarf: keiner
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 1 Tisch
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w09KG09Origami
